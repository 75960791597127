import {
  useMemo,
  useEffect,
  useCallback,
  useState,
  createContext,
  useContext,
} from 'react';

interface IValue {
  currentTheme: string | undefined;
  updatePreference: (theme: string) => void;
  preference: string;
}

const ThemeContext = createContext<IValue>({} as IValue);

export const useTheme = (): IValue => useContext(ThemeContext);

const ThemeProvider: React.FC = ({ children }) => {
  const [preference, setPreference] = useState('system');

  const [isMounted, setMounted] = useState(false);

  const getThemeOnPreference = () => {
    if (typeof window !== 'undefined') {
      if (preference === 'system') {
        const prefersLight = window.matchMedia(
          '(prefers-color-scheme: light)'
        ).matches;

        const prefersDark = window.matchMedia(
          '(prefers-color-scheme: dark)'
        ).matches;

        if (prefersDark) {
          return 'dark';
        }

        if (prefersLight) {
          return 'light';
        }

        if (!prefersDark && !prefersDark) {
          return 'light';
        }
      }

      if (preference === 'light') {
        return 'light';
      }

      if (preference === 'dark') {
        return 'dark';
      }
    }
  };
  const [currentTheme, setCurrentTheme] = useState(getThemeOnPreference());

  const updatePreference = useCallback(
    (theme: string) => {
      setPreference(theme);
      if (typeof window !== 'undefined') {
        localStorage.setItem('preference', theme);
      }
    },
    [setPreference]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const storedPreference = localStorage.getItem('preference');

      setPreference(storedPreference || 'system');
    }
  }, []);

  useEffect(() => {
    setCurrentTheme(getThemeOnPreference());
    setMounted(true);
  }, [preference, getThemeOnPreference]);

  const value = useMemo(() => {
    return {
      currentTheme,
      setCurrentTheme,
      updatePreference,
      preference,
    };
  }, [currentTheme, setCurrentTheme, updatePreference, preference]);

  return (
    <ThemeContext.Provider value={value}>
      {isMounted && children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
