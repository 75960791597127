import { AppProps } from 'next/app';
import ThemeProvider from '@/components/global/Providers/Theme';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import Head from 'next/head';
import SettingsProvider from '@/components/global/Providers/Footer';
import ClientCasesProvider from '@/components/global/Providers/ClientCases';

export default function App({ Component, pageProps }: AppProps): JSX.Element {
  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <ThemeProvider>
        <SettingsProvider>
          <ClientCasesProvider>
            <Component {...pageProps} />
          </ClientCasesProvider>
        </SettingsProvider>
      </ThemeProvider>
    </>
  );
}
