import { CMS_URL } from '@/config';
import { ITestimonies } from '@/pages/api/clients-cases';
import axios from 'axios';
import { createContext, useContext, useState, useMemo, useEffect } from 'react';

export interface ITestemunho {
  nome: string;
  src: string;
  cargo: string;
  testemunho: string;
  nomeEmpresa: string;
}

const ClientCasesContext = createContext({} as IValue);

export const useClientCases = (): IValue => useContext(ClientCasesContext);

interface IValue {
  clientCases: ITestemunho[];
}

const ClientCasesProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [clientCases, setClientCases] = useState({} as ITestemunho[]);

  useEffect(() => {
    async function getSettings() {
      const { data } = await axios.get('/api/clients-cases');

      const testimonies = data.map((testemunho: ITestimonies) => {
        return {
          nome: testemunho.name,
          src: `${CMS_URL}/assets/${testemunho.logo}`,
          cargo: testemunho.role,
          testemunho: testemunho.testimony,
          nomeEmpresa: testemunho.company_name,
        };
      });

      setClientCases(testimonies);
    }
    getSettings();
  }, []);

  const value = useMemo(() => {
    return {
      clientCases: clientCases,
    };
  }, [clientCases]);

  return (
    <ClientCasesContext.Provider value={value}>
      {children}
    </ClientCasesContext.Provider>
  );
};

export default ClientCasesProvider;
