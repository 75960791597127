import axios from 'axios';
import { createContext, useContext, useState, useMemo, useEffect } from 'react';

export interface ISettings {
  id: string;
  cnpj: string;
  copyright: string;
  youtube: string;
  instagram: string;
  whatsapp: string;
}

const SettingsContext = createContext({} as IValue);

export const useSettings = (): IValue => useContext(SettingsContext);

interface IValue {
  settings: ISettings;
}

const SettingsProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const [settings, setSettings] = useState({} as ISettings);

  useEffect(() => {
    async function getSettings() {
      const { data } = await axios.get('/api/settings');

      setSettings(data);
    }
    getSettings();
  }, []);

  const value = useMemo(() => {
    return {
      settings: settings,
    };
  }, [settings]);

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
